prismanoteApp.config(['$locationProvider', '$sceProvider', '$stateProvider', '$urlMatcherFactoryProvider', '$urlRouterProvider',
  function ($locationProvider, $sceProvider, $stateProvider, $urlMatcherFactoryProvider, $urlRouterProvider) {
    $urlMatcherFactoryProvider.strictMode(false)

    $urlRouterProvider
      .when('/shop/:nameSlug', '/shop/:nameSlug/home')
      .when('/admin', '/admin/home')

      // Show homepage by default in the retailer portal
      .when('/retailer', '/retailer/home/')
      // Show homepage by default in the user account page
      .when('/my-account', '/my-account/home/')
      .when('/brand', '/brand/welcome')

      .otherwise('/home')

    $stateProvider

      // #region Admin routes
      .state('admin', {
        abstract: true,
        templateUrl: '/views/admin/index.html',
        data: {
          restricted: true,
          role: 'admin'
        }
      })
      .state('admin.home', {
        url: '/admin/home',
        templateUrl: '/views/admin/home.html',
        controller: 'adminHomeController'
      })
      .state('admin.eanlist', {
        url: '/admin/duplicate-ean-list',
        templateUrl: '/views/admin/duplicate-ean-list.html',
        controller: 'adminDuplicateEANController'
      })
      .state('admin.namesluglist', { 
        url: '/admin/duplicate-nameslug-list',
        templateUrl: '/views/admin/duplicate-nameslug-list.html',
        controller: 'adminDuplicateNameslugController'
      })
      .state('admin.product-merge-records', {
        url: '/admin/product-merge-records',
        templateUrl: '/views/admin/admin-product-merge-records.html',
        controller: 'adminProductMergeController'
      })
      .state('admin.users', {
        url: '/admin/users',
        templateUrl: '/views/admin/users.html',
        controller: 'usersController'
      })
      .state('admin.user', {
        url: '/admin/user/:userId',
        templateUrl: '/views/admin/user-edit.html',
        controller: 'usersController'
      })
      .state('admin.add-user', {
        url: '/admin/users/add',
        templateUrl: '/views/add-user.html',
        controller: 'addUserController'
      })
      .state('admin.check-transactions', {
        url: '/admin/transactions',
        templateUrl: '/views/admin/transactions.html',
        controller: 'adminTransactionController'
      })
      .state('admin.shoppurchase', {
        url: '/admin/transaction/:transactionId',
        templateUrl: '/views/admin/shoppurchase.html',
        controller: 'adminShopPurchaseController',
        params: {
          transactionId: null
        }
      })
      .state('admin.shops', {
        url: '/admin/shops',
        templateUrl: '/views/admin/shops.html',
        controller: 'shopsController'
      })
      .state('admin.shop', {
        url: '/admin/shop/:nameSlug',
        templateUrl: '/views/admin/shop-edit.html',
        controller: 'shopsController'
      })
      .state('admin.brands', {
        url: '/admin/brands',
        templateUrl: '/views/admin/brands.html',
        controller: 'brandsController'
      })
      .state('admin.brand', {
        url: '/admin/brand/:nameSlug',
        templateUrl: '/views/admin/brand-edit.html',
        controller: 'adminBrandsController'
      })
      .state('admin.brandStartingDigits', {
        url: '/admin/brand-stating-digits/:nameSlug',
        templateUrl: '/views/admin/brand-products-staring-digits.html',
        controller: 'adminBrandProductsStartingDigitsController'
      })
      .state('admin.campaigns', {
        url: '/admin/campaigns',
        templateUrl: '/views/admin/campaigns.html',
        controller: 'adminCampaignsController'
      })
      .state('admin.campaign', {
        url: '/admin/campaigns/:_id',
        templateUrl: '/views/admin/campaign.html',
        controller: 'adminCampaignController'
      })
      .state('admin.social-portals', {
        url: '/admin/social-portals',
        templateUrl: '/views/admin/social-portals.html',
        controller: 'socialPortalsController'
      })
      .state('admin.news', {
        url: '/admin/news',
        templateUrl: '/views/admin/news.html',
        controller: 'adminNewsController'
      })
      .state('admin.news-item', {
        url: '/admin/news/:nameSlug',
        templateUrl: '/views/admin/news-item.html',
        controller: 'adminNewsItemController'
      })
      .state('admin.news-item-new', {
        url: '/admin/news/news-item-new',
        templateUrl: '/views/admin/news-item-new.html',
        controller: 'adminNewsItemController'
      })

      // .state('admin.products', {
      // 	url: '/admin/products',
      // 	templateUrl: '/views/admin/products.html',
      // 	controller: 'adminProductsController'
      // })

      .state('admin.products', {
        url: '/admin/products',
        templateUrl: '/views/admin/products-new.html',
        controller: 'adminProductsNewController'
      })

      .state('admin.products-to-merge', {
        url: '/admin/products-to-merge',
        templateUrl: '/views/admin/products-to-merge.html',
        controller: 'adminProductsToMergeController'
      })
      .state('admin.companies-to-merge', {
        url: '/admin/companies-to-merge',
        templateUrl: '/views/admin/companies-to-merge.html',
        controller: 'adminCompaniesToMergeController'
      })
      .state('admin.products-to-delete', {
        url: '/admin/products-to-delete',
        templateUrl: '/views/admin/products-to-delete.html',
        controller: 'adminProductsToDeleteController'
      })
      .state('admin.newProduct', {
        url: '/admin/add-product',
        templateUrl: '/views/admin/product.html',
        controller: 'adminProductController'
      })
      // .state('admin.product', {
      // 	url: '/admin/edit-product/:nameSlug',
      // 	templateUrl: '/views/admin/product.html',
      // 	controller: 'adminProductController',
      // })
      .state('admin.newAdminProduct', {
        url: '/admin/add-new-product',
        templateUrl: '/views/admin/product-new.html',
        controller: 'adminProductNewController'
      })
      .state('admin.product', {
        url: '/admin/edit-new-product/:nameSlug',
        templateUrl: '/views/admin/compare-product.html',
        controller: 'compareProductController'
      })
      .state('admin.merge-product', {
        url: '/admin/merge-product/:chosenProductId/:searchedProductId',
        templateUrl: '/views/admin/admin-merge-product.html',
        controller: 'adminMergeProductController'
      })
      .state('admin.product-suggestion-verify', {
        url: '/admin/product-suggestion-verify/:nameSlug',
        templateUrl: '/views/admin/compare-product-new.html',
        controller: 'compareProductNewController'
      })
      .state('admin.imports', {
        url: '/admin/imports',
        templateUrl: '/views/admin/imports.html',
        controller: 'adminImportsController'
      })
      .state('admin.import', {
        url: '/admin/import/:id',
        templateUrl: '/views/admin/import.html',
        controller: 'adminImportsController'
      })
      .state('admin.product-import', {
        url: '/admin/product-import',
        templateUrl: '/views/admin/product-import.html',
        controller: 'adminProductImportController'
      })
      .state('admin.product-starting-digit-update', {
        url: '/admin/product-starting-digit-update',
        templateUrl: '/views/admin/product-starting-digit-update.html',
        controller: 'adminProductStartingDigitUpdateController'
      })
      .state('admin.companies', {
        url: '/admin/companies',
        templateUrl: '/views/admin/companies.html',
        controller: 'adminCompanyController'
      })
      .state('admin.company', {
        url: '/admin/company/:nameSlug',
        templateUrl: '/views/admin/company-edit.html',
        controller: 'adminCompanyController'
      })
      // .state('admin.companies-to-delete', {
      // 	url: '/admin/companies-to-delete',
      // 	templateUrl: '/views/admin/companies-to-delete.html',
      // 	controller: 'adminCompaniesToDeleteController'
      // })

      .state('admin.shop-company-delete-suggestions', {
        url: '/admin/shop-company-delete-suggestions',
        templateUrl: '/views/admin/shop-company-delete-suggestions.html',
        controller: 'adminShopCompanyDeleteSuggestionsController'
      })
      .state('admin.product-collections', {
        url: '/admin/product-collections',
        templateUrl: '/views/admin/product-collections.html',
        controller: 'adminProductCollectionsController'
      })
      .state('admin.collection', {
        url: '/admin/collection/:id',
        templateUrl: '/views/admin/collection.html',
        controller: 'adminProductCollectionsController'
      })
      .state('admin.taxes', {
        url: '/admin/taxes',
        templateUrl: '/views/admin/taxes.html',
        controller: 'adminTaxesController'
      })
      .state('admin.tax', {
        url: '/admin/tax?id',
        templateUrl: '/views/admin/tax.html',
        controller: 'adminTaxesController'
      })
      .state('admin.retailerjoininglist', {
        url: '/admin/retailerjoininglist/:campaignId',
        templateUrl: '/views/campaign/campaign-retailer-joining-list.html',
        controller: 'campaignRetailerJoiningList'
      })
      .state('admin.campaigndetail', {
        url: '/admin/campaign/:campaignId',
        templateUrl: '/views/campaign/campaign-detail.html',
        controller: 'retailerBrandCampaignDetailController'
      })
      .state('admin.translations', {
        url: '/admin/translations',
        templateUrl: '/views/admin/translations.html',
        controller: 'adminTranslationsController'
      })
      .state('admin.updates', {
        url: '/admin/updates',
        templateUrl: '/views/admin/updates.html',
        controller: 'adminUpdatesController'
      })
      .state('admin.blocks', {
        url: '/admin/blocks',
        templateUrl: '/views/admin/blocks.html',
        controller: 'adminBlocksController'
      })
      .state('admin.transactions-import', {
        url: '/admin/transactions-import',
        templateUrl: '/views/admin/transactions-import.html',
        controller: 'adminTransactionImportController'
      })

      // #endregion
      // #region Retailer routes here
      .state('retailer.buildertemplate', {
        url: '/web-builder-template/:websiteId',
        templateUrl: '/views/grapes/template-chooser.html',
        controller: 'grapeTemplateController'
      })
      .state('buildereditor', {
        url: '/web-builder-editor/:shopId/:websiteId',
        templateUrl: '/views/grapes/editor-page.html',
        controller: 'grapeEditorController'
      })
      .state('retailer', {
        abstract: true,
        templateUrl: '/views/retailer/index.html',
        controller: 'retailerController',
        data: {
          restricted: true,
          role: 'retailer'
        }
      })
      .state('retailer.home', {
        url: '/retailer/home/',
        templateUrl: '/views/retailer/home.html',
        controller: 'retailerHomeController',
        data: {
          pageTitle: 'DASHBOARD'
        }
      })
      .state('retailer.jspm', {
        url: '/retailer/jspm/',
        templateUrl: '/views/retailer/jspm.html',
        controller: 'retailerJspmController',
        data: {
          pageTitle: 'JSPM_HELP'
        }
      })
      .state('retailer.till', {
        url: '/retailer/till?token', // Authtoken is when we are passing it from the new platform
        templateUrl: '/views/retailer/till.html',
        controller: 'retailerTillController',
        params: {
          customer: null,
          items: null,
          transactionId: null,
          transactionNumber: null,
          refund: false,
          checkout: false,
          concept: false,
          payLater: false
        },
        data: {
          rights: 'USE_SERVICE_APP',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'CASH_REGISTER'
        }
      })
      .state('retailer.checkout', {
        abstract: true,
        data: {
          restricted: true,
          role: 'retailer',
          rights: 'USE_SERVICE_APP',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'CHECKOUT'
        }
      })
      .state('retailer.checkout.start', {
        url: '/retailer/checkout/:transactionId',
        templateUrl: '/views/retailer/checkout.html',
        controller: 'retailerCheckoutController',
        params: {
          transactionId: null,
          deposit: null,
          details: null,
          needToDirectDeductStock: false,
          payLater: false,
          payLaterAmount: 0
        },
        data: {
          rights: 'USE_SERVICE_APP',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'CHECKOUT'
        }
      })
      .state('retailer.checkout.completed', {
        url: '/retailer/checkout/completed/:transactionId?print',
        templateUrl: '/views/retailer/checkout-completed.html',
        controller: 'retailerCheckoutController',
        params: {
          skipPrint: false,
          transactionId: null,
          paymentStatus: null,
          saveOrder: false,
          printReceipt: false,
          payLater: false,
          merchantReceipt: false
        },
        data: {
          rights: 'USE_SERVICE_APP',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'CHECKOUT'
        }
      })
      .state('retailer.checkout.ccv', {
        url: '/retailer/checkout/ccv/:transactionId',
        templateUrl: '/views/retailer/checkout-ccv.html',
        controller: 'retailerCheckoutController',
        params: {
          transactionId: null,
        }
      })
      .state('retailer.dayclosure', {
        url: '/retailer/day-closure',
        templateUrl: '/views/retailer/day-closure.html',
        controller: 'retailerDayclosureController',
        data: {
          rights: 'MANAGE_DAYSTATE',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'DAY_CLOSURE'
        }
      })
      .state('retailer.state-overview', {
        url: '/retailer/day-closure/overview',
        templateUrl: '/views/retailer/day-closure-overview.html',
        controller: 'retailerDayclosureController',
        params: {
          stateId: null
        },
        data: {
          rights: 'MANAGE_DAYSTATE',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'DAY_CLOSURE'
        }
      })
      .state('retailer.drawer-log', {
        url: '/retailer/day-closure/drawer-log',
        templateUrl: '/views/retailer/drawer-log.html',
        controller: 'retailerDrawerLogController',
        params: {
          stateId: null
        },
        data: {
          rights: 'MANAGE_DAYSTATE',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'DAY_CLOSURE'
        }
      })
      .state('retailer.state-overview-detail', {
        url: '/retailer/day-closure/overview/:stateId',
        templateUrl: '/views/retailer/day-closure-overview-detail.html',
        controller: 'retailerDayclosureController',
        params: {
          stateId: null
        },
        data: {
          rights: 'MANAGE_DAYSTATE',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'DAY_CLOSURE'
        }
      })
      .state('retailer.state-overview-detail-old', {
        url: '/retailer/day-closure/overview-old/:stateId',
        templateUrl: '/views/retailer/day-closure-overview-detail-old.html',
        controller: 'retailerDayclosureController',
        params: {
          stateId: null
        },
        data: {
          rights: 'MANAGE_DAYSTATE',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'DAY_CLOSURE'
        }
      })
      .state('retailer.statistics1', {
        url: '/retailer/statistics/new',
        templateUrl: '/views/retailer/statistics-new.html',
        controller: 'retailerStatisticsControllerNew',
        data: {
          rights: 'STATISTICS',
          module: 'PACKAGE_SALES_STATISTICS',
          pageTitle: 'SELL_STATISTICS'
        }
      })
      .state('retailer.upgrade', {
        url: '/retailer/upgrade?return&orderid',
        templateUrl: '/views/retailer/upgrade.html',
        controller: 'retailerUpgradeController',
        data: {
          rights: 'MANAGE_SHOP_SETTINGS',
          pageTitle: 'UPGRADE'
        }
      })
      .state('retailer.add-campaign', {
        url: '/retailer/add-campaign',
        templateUrl: '/views/retailer/add-campaign.html',
        data: {
          rights: 'MANAGE_SOCIALMEDIA_ACTIONS',
          module: 'PACKAGE_MARKETING_WITH_SUPPLIERS',
          pageTitle: 'CAMPAIGNS'
        }
      })
      .state('retailer.myshop', {
        url: '/retailer/shop',
        templateUrl: '/views/retailer/shop.html',
        controller: 'retailerShopController',
        data: {
          rights: 'MANAGE_SHOP_SETTINGS',
          pageTitle: 'EDIT_SHOP'
        },
        params: {
          activeTab: 0
        }
      })
      .state('retailer.registration', {
        url: '/retailer/registration',
        templateUrl: '/views/retailer/registration-process.html',
        controller: 'retailerRegistrationProcessController',
        data: {
          rights: 'MANAGE_SHOP_SETTINGS',
          pageTitle: 'EDIT_SHOP'
        }
      })
      .state('retailer.myuser', {
        url: '/retailer/user?userId',
        templateUrl: '/views/retailer/user.html',
        controller: 'retailerUserController',
        data: {
          pageTitle: 'MY_ACCOUNT'
        }
      })
      .state('retailer.employees', {
        url: '/retailer/employees',
        templateUrl: '/views/retailer/employees.html',
        controller: 'employeesRetailerController',
        data: {
          pageTitle: 'EMPLOYEES'
        }
      })
      .state('retailer.addemployee', {
        url: '/retailer/addemployee',
        templateUrl: '/views/retailer/retailer-add-employee.html',
        controller: 'retailerAddEmployeeController',
        data: {
          pageTitle: 'EMPLOYEES'
        }
      })
      .state('retailer.product-import', {
        url: '/retailer/assortment/import',
        templateUrl: '/views/retailer1/product-import.html',
        controller: 'retailer1ProductImportController',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: 'PACKAGE_LINK_WITH_PRODUCT_DATABASE',
          pageTitle: 'PRODUCT_IMPORT'
        }
      })

      .state('retailer.gift-card-import', {
        url: '/retailer/assortment/gift-card-import',
        templateUrl: '/views/retailer1/gift-card-import.html',
        controller: 'retailerGiftCardImportController',
        data: {
          rights: 'MANAGE_GIFT_CARD',
          module: 'PACKAGE_LINK_WITH_PRODUCT_DATABASE',
          pageTitle: 'GIFT_CARD_IMPORT'
        }
      })
      .state('retailer.customer-import', {
        url: '/retailer/assortment/customer-import',
        templateUrl: '/views/retailer/customer-import.html',
        controller: 'retailerCustomerImportController',
        data: {
          rights: 'MANAGE_CUSTOMERS',
          module: 'PACKAGE_CUSTOMER_MANAGEMENT',
          pageTitle: 'CUSTOMER_IMPORT'
        }
      })
      .state('retailer.transaction-import', {
        url: '/retailer/assortment/transaction-import',
        templateUrl: '/views/retailer/transaction-import.html',
        controller: 'retailerTransactionImportController',
        data: {
          rights: 'MANAGE_CUSTOMERS',
          module: 'PACKAGE_CUSTOMER_MANAGEMENT',
          pageTitle: 'TRANSACTION_IMPORT'
        }
      })
      .state('retailer.add-product', {
        url: '/retailer/assortment/add-product/:orderNumber/:itemId',
        templateUrl: '/views/retailer1/product.html',
        controller: 'retailerProduct1Controller',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCTS'
        }
      })
      .state('retailer.edit-product', {
        url: '/retailer/assortment/edit-product/:orderNumber/:_id/:status',
        templateUrl: '/views/retailer1/product.html',
        controller: 'retailerProduct1Controller',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCTS'
        }
      })
      .state('retailer.assortment', {
        url: '/retailer/assortment?mode',
        templateUrl: '/views/retailer1/assortment.html',
        controller: 'retailerAssortment1Controller',
        params: {
          modalFor: null,
          stockMode: true
        },
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'ASSORTMENT'
        }
      })
      .state('retailer.inventories', {
        url: '/retailer/inventory',
        templateUrl: '/views/retailer1/inventory.html',
        controller: 'retailerInventoryController',
        data: {
          rights: 'INVENTORY',
          module: '',
          pageTitle: 'INVENTORY'
        }
      })
      .state('retailer.inventory', {
        url: '/retailer/inventory/:collection',
        templateUrl: '/views/retailer1/inventory-collection.html',
        controller: 'retailerInventoryCollectionController',
        data: {
          rights: 'INVENTORY',
          module: '',
          pageTitle: 'INVENTORY'
        }
      })
      .state('retailer.compare-product', {
        url: '/retailer/compare-product/:nameSlug/:mergeProductnameSlug',
        templateUrl: '/views/retailer1/compare-product.html',
        controller: 'retailerCompareProduct1Controller',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCTS'
        }
      })
      .state('retailer.imports', {
        url: '/retailer/imports',
        templateUrl: '/views/retailer/imports.html',
        controller: 'retailerImportsController',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCT_IMPORT'
        }
      })
      .state('retailer.import', {
        url: '/retailer/import',
        templateUrl: '/views/retailer/import.html',
        controller: 'retailerImportsController',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCT_IMPORT'
        }
      })
      .state('retailer.orders', {
        url: '/retailer/weborders',
        templateUrl: '/views/retailer/weborders.html',
        controller: 'retailerWebordersController',
        data: {
          rights: 'MANAGE_WEBSHOP_ORDER',
          module: 'PACKAGE_SELL_DIRECTLY_ON_YOUR_WEBSHOP',
          pageTitle: 'WEBORDERS'
        }
      })
      .state('retailer.orderDetails', {
        url: '/retailer/webshop/:transactionId',
        templateUrl: '/views/retailer/weborder.html',
        controller: 'retailerWeborderController',
        data: {
          rights: 'MANAGE_WEBSHOP_ORDER',
          module: 'PACKAGE_SELL_DIRECTLY_ON_YOUR_WEBSHOP',
          pageTitle: 'WEBORDERS'
        }
      })
      .state('retailer.purchase-orders', {
        url: '/retailer/purchase-orders/:company',
        templateUrl: '/views/retailer/purchase-orders.html',
        controller: 'retailerPurchaseOrdersController',
        params: {
          company: { squash: true, value: null }
        },
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_SUPPLIER_MANAGEMENT',
          pageTitle: 'ALL_PURCHASE_ORDERS'
        }
      })
      .state('retailer.autocreated-purchase-order', {
        url: '/retailer/autocreated-purchase-order',
        templateUrl: '/views/retailer/autocreated-purchase-order.html',
        controller: 'retailerAutoCreatedPurchaseOrderController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_PURCHASE_ORDERS',
          pageTitle: 'ALL_PURCHASE_ORDERS'
        }
      })
      .state('brand.autocreated-purchase-order', {
        url: '/brand/autocreated-purchase-order',
        templateUrl: '/views/brand/brand-autocreated-purchase-order.html',
        controller: 'brandAutoCreatedPurchaseOrderController',
      })
      .state('brand.recommendation-management', {
        url: '/brand/recommendation-management',
        templateUrl: '/views/brand/brand-recommendation-management.html',
        controller: 'brandRecommendationManagementController',
      })
      .state('retailer.add-bulk-change', {
        url: '/retailer/add-bulk-change',
        templateUrl: '/views/retailer/add-bulk-change.html',
        controller: 'retailerAddBulkChangeController'
      })
      .state('retailer.name-and-descriptions-bulk-change', {
        url: '/retailer/name-and-descriptions-bulk-change',
        templateUrl: '/views/retailer/name-and-descriptions-bulk-change.html',
        controller: 'retailerNameAndDescriptionsBulkChange'
      })

      .state('retailer.purchase-order', {
        url: '/retailer/purchase-order/:orderNo',
        templateUrl: '/views/retailer/purchase-order.html',
        controller: 'retailerPurchaseOrderController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_PURCHASE_ORDERS',
          pageTitle: 'ALL_PURCHASE_ORDERS'
        }
      })

      // .state('retailer.purchase-order', {
      //   url: '/retailer/purchase-order/:orderNo',
      //   templateUrl: '/views/retailer/purchase-order-new.html',
      //   controller: 'retailerPurchaseOrderControllerNew',
      //   data: {
      //     rights: 'MANAGE_SUPPLIERS',
      //     module: 'PACKAGE_PURCHASE_ORDERS',
      //     pageTitle: 'ALL_PURCHASE_ORDERS'
      //   }
      // })

      // .state('retailer.recommendation-order', {
      //   url: '/retailer/recommendation-order/:orderNo',
      //   templateUrl: '/views/retailer/recommendation-order.html',
      //   controller: 'retailerRecommendationOrderController',
      //   data: {
      //     rights: 'MANAGE_SUPPLIERS',
      //     module: 'PACKAGE_PURCHASE_ORDERS',
      //     pageTitle: 'ALL_PURCHASE_ORDERS'
      //   }
      // })

      .state('retailer.recommendation-order', { 
        url: '/retailer/recommendation-order/:orderNo',
        templateUrl: '/views/retailer/recommendation-order-new.html',
        controller: 'retailerRecommendationOrderControllerNEW',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_PURCHASE_ORDERS',
          pageTitle: 'ALL_PURCHASE_ORDERS'
        }
      })

      .state('retailer.purchase-order-mail-to-supplier', {
        url: '/retailer/purchase-order-mail-to-supplier/:orderNo',
        templateUrl: '/views/retailer/purchase-order-mail-to-supplier.html',
        controller: 'retailerPurchaseOrderMailToSupplierController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_PURCHASE_ORDERS',
          pageTitle: 'ALL_PURCHASE_ORDERS'
        }
      })
      .state('retailer.purchase-order-mail-req-prod-images', {
        url: '/retailer/purchase-order-mail-req-prod-images/:orderNo',
        templateUrl: '/views/retailer/purchaseorder-mail-req-prod-images.html',
        controller: 'purchaseOrderMailReqProdImagesController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_PURCHASE_ORDERS',
          pageTitle: 'ALL_PURCHASE_ORDERS'
        }
      })
      .state('retailer.customers', {
        url: '/retailer/customers',
        templateUrl: '/views/retailer/customers.html',
        controller: 'retailerCustomersController',
        data: {
          rights: 'MANAGE_CUSTOMERS',
          module: 'PACKAGE_CUSTOMER_MANAGEMENT',
          pageTitle: 'CUSTOMERS'
        }
      })
      .state('retailer.customer', {
        url: '/retailer/customer/:id',
        templateUrl: '/views/retailer/customer.html',
        controller: 'retailerCustomerController',
        data: {
          rights: 'MANAGE_CUSTOMERS',
          module: 'PACKAGE_CUSTOMER_MANAGEMENT',
          pageTitle: 'CUSTOMER'
        }
      })
      .state('retailer.service', {
        url: '/retailer/service',
        templateUrl: '/views/retailer/service.html',
        controller: 'retailerServiceController',
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          module: 'PACKAGE_PROCESS_REPAIRS',
          pageTitle: 'SERVICES'
        }
      })
      .state('retailer.transactions', {
        url: '/retailer/transactions',
        templateUrl: '/views/retailer/transactions-and-offers.html',
        controller: 'retailerTransactionsController',
        data: {
          rights: 'MANAGE_TRANSACTIONS_OFFERS',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'TRANSACTIONS'
        }
      })
      .state('retailer.deleted-transactions', {
        url: '/retailer/deleted-transactions',
        templateUrl: '/views/retailer/deleted-transactions.html',
        controller: 'retailerDeletedTransactionsController',
        data: {
          rights: 'MANAGE_TRANSACTIONS_OFFERS',
          module: 'PACKAGE_CHECKOUT_AND_OFFERS',
          pageTitle: 'DELETED_TRANSACTIONS'
        }
      })
      .state('retailer.financial', {
        url: '/retailer/financial',
        templateUrl: '/views/retailer/financial.html',
        controller: 'retailerFinancialController',
        data: {
          rights: 'STATISTICS',
          module: 'PACKAGE_SALES_STATISTICS',
          pageTitle: 'FINANCIAL_DETAILS'
        }
      })
      .state('retailer.payments', {
        url: '/retailer/financial/payments',
        templateUrl: '/views/retailer/payments.html',
        controller: 'retailerPaymentsController',
        data: {
          rights: 'STATISTICS',
          module: 'PACKAGE_SALES_STATISTICS',
          pageTitle: 'PAYMENTS'
        }
      })
      .state('retailer.suppliers', {
        url: '/retailer/suppliers',
        templateUrl: '/views/retailer/suppliers.html',
        controller: 'retailerSuppliersController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_SUPPLIER_MANAGEMENT',
          pageTitle: 'TEXT_HEAD_SUPPLIERS_RETAILER_HOME'
        }
      })
      .state('retailer.service-item', {
        url: '/retailer/service/:transactionId',
        templateUrl: '/views/retailer/service-item.html',
        controller: 'retailerServiceItemController',
        params: {
          transactionId: null
        },
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          pageTitle: 'SERVICE_ITEM'
        }
      })
      .state('retailer.repair', {
        url: '/retailer/repair/:transactionId',
        templateUrl: '/views/retailer/repair.html',
        controller: 'retailerRepairController',
        params: {
          transactionId: null
        },
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          pageTitle: 'REPAIR'
        }
      })
      .state('retailer.shop', {
        url: '/retailer/shop/:transactionId',
        templateUrl: '/views/retailer/shoppurchase.html',
        controller: 'retailerShopPurchaseController',
        params: {
          transactionId: null
        },
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          pageTitle: 'TRANSACTION'
        }
      })
      .state('retailer.special', {
        url: '/retailer/special/:transactionId',
        templateUrl: '/views/retailer/special.html',
        controller: 'retailerSpecialController',
        params: {
          transactionId: null
        },
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          pageTitle: 'SPECIAL'
        }
      })
      .state('retailer.offer', {
        url: '/retailer/offer/:transactionId',
        templateUrl: '/views/retailer/offer.html',
        controller: 'retailerOfferController',
        params: {
          transactionId: null
        },
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          pageTitle: 'OFFER'
        }
      })
      .state('retailer.gold', {
        url: '/retailer/gold/:transactionId',
        templateUrl: '/views/retailer/goldpurchase.html',
        controller: 'retailerGoldPurchaseController',
        params: {
          transactionId: null
        },
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          pageTitle: 'GOLD_PURCHASE'
        }
      })
      .state('retailer.giftcard', {
        url: '/retailer/giftcard/:transactionId',
        templateUrl: '/views/retailer/giftcard.html',
        controller: 'retailerGiftcardDetailController',
        params: {
          transactionId: null
        },
        data: {
          rights: 'MANAGE_REPAIRS_SPECIAL',
          pageTitle: 'GIFTCARD'
        }
      })
      .state('retailer.add-company', {
        url: '/retailer/add-company',
        templateUrl: '/views/retailer/add-company.html',
        controller: 'retailerSupplierController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_SUPPLIER_MANAGEMENT',
          pageTitle: 'SUPPLIERS'
        }
      })
      .state('retailer.edit-supplier', {
        url: '/retailer/edit-supplier/:nameSlug', // have to copy this
        templateUrl: '/views/retailer/edit-supplier.html',
        controller: 'retailerEditSupplierController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_SUPPLIER_MANAGEMENT',
          pageTitle: 'SUPPLIER'
        }
      })
      .state('retailer.supplier', {
        url: '/retailer/supplier/:nameSlug', // have to copy this
        templateUrl: '/views/retailer/supplier.html',
        controller: 'retailerEditSupplierController',
        data: {
          rights: 'MANAGE_SUPPLIERS',
          module: 'PACKAGE_SUPPLIER_MANAGEMENT',
          pageTitle: 'SUPPLIER'
        }
      })
      .state('retailer.web-builder-home', {
        url: '/web-builder-home',
        templateUrl: '/views/grapes/prisma-editor-home.html',
        controller: 'grapeHomeController',
        data: {
          rights: 'USE_WEBSITEBUILDER',
          module: 'PACKAGE_EDIT_YOUR_OWN_SITE',
          pageTitle: 'WEBSITE_BUILDER'
        }
      })
      .state('retailer.web-builder-home-new', {
        url: '/web-builder-home-new',
        templateUrl: '/views/grapes/duda-editor-home.html',
        controller: 'dudaHomeController',
        data: {
          rights: 'USE_WEBSITEBUILDER',
          module: 'PACKAGE_EDIT_YOUR_OWN_SITE',
          pageTitle: 'WEBSITE_BUILDER'
        }
      })
      .state('retailer.web-template-list', {
        url: '/web-template-list',
        templateUrl: '/views/grapes/prisma-template-list.html',
        controller: 'webTemplateController',
        data: {
          rights: '',
          module: '',
          pageTitle: 'WEBSITE_TEMPLATE_LIST'
        }
      })
      .state('retailer.new_campaign', {
        url: '/retailer/new-campaign',
        templateUrl: '/views/retailer/new-campaign.html',
        controller: 'retailerCampaignController',
        data: {
          rights: 'MANAGE_SOCIALMEDIA_ACTIONS',
          module: '',
          pageTitle: 'CAMPAIGNS'
        }
      })
      .state('retailer.notifications', {
        url: '/retailer/notifications',
        templateUrl: '/views/retailer/notifications.html',
        controller: 'retailerNotificationsController',
        data: {
          rights: '',
          module: '',
          pageTitle: 'NOTIFICATIONS'
        }
      })
      .state('retailer.no-rights', {
        url: '/no-rights',
        templateUrl: 'views/retailer/no-rights.html',
        controller: 'authController',
        data: {
          pageTitle: 'NO_RIGHTS'
        }
      })

      // #endregion

      // #region Brand routes here
      .state('brand', {
        abstract: true,
        templateUrl: '/views/brand/index.html',
        controller: 'brandController',
        data: {
          restricted: true,
          role: 'wholesaler',
          pageTitle: 'DASHBOARD'
        }
      })
      .state('brand.welcome', {
        url: '/brand/welcome',
        templateUrl: '/views/brand/welcome.html',
        controller: 'welcomeBrandController',
        breadcrumb: 'Welcome',
        data: {
          pageTitle: 'WELCOME'
        }
      })
      .state('brand.assortment', {
        url: '/brand/assortment',
        templateUrl: '/views/brand/assortment.html',
        controller: 'brandAssortmentController',
        breadcrumb: 'Assortiment',
        params: {
          modalFor: null,
          stockMode: true
        },
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'ASSORTMENT'
        }
      })
      .state('brand.home', {
        url: '/brand/home',
        templateUrl: '/views/brand/home.html',
        controller: 'brandHomeController',
        data: {
          pageTitle: 'DASHBOARD'
        }
      })
      .state('brand.notifications', {
        url: '/brand/notifications',
        templateUrl: '/views/brand/notifications.html',
        controller: 'brandNotificationController',
        data: {
          pageTitle: 'NOTIFICATIONS'
        }
      })
      .state('brand.company-info', {
        url: '/brand/company-info',
        templateUrl: '/views/brand/company-info.html',
        controller: 'brandCompanyInfoController',
        breadcrumb: 'Company Info',
        data: {
          pageTitle: 'COMPANY_INFO'
        }
      })
      .state('brand.myuser', {
        url: '/brand/user/:userId',
        templateUrl: '/views/brand/user.html',
        controller: 'brandUserController',
        breadcrumb: 'User',
        data: {
          pageTitle: 'ACCOUNT'
        }
      })
      .state('brand.marketingUser', {
        url: '/brand/marketing-user',
        templateUrl: '/views/brand/marketingUser.html',
        controller: 'brandMarketingUserController',
        data: {
          pageTitle: 'MARKETING'
        }
      })
      .state('brand.brand', {
        url: '/brand/brand/:nameSlug',
        templateUrl: '/views/brand/brand-edit.html',
        controller: 'brandBrandsController',
        breadcrumb: 'Brands',
        data: {
          pageTitle: 'BRANDS'
        }
      })
      .state('brand.performance', {
        url: '/brand/performance',
        templateUrl: '/views/brand/performance.html',
        controller: 'brandPerformanceController',
        breadcrumb: 'Performance',
        data: {
          pageTitle: 'PERFORMANCE'
        }
      })
      .state('brand.shops', {
        url: '/brand/shops',
        templateUrl: '/views/brand/shops.html',
        controller: 'shopBrandController',
        breadcrumb: 'Shops and Contributions',
        data: {
          pageTitle: 'SHOPS'
        }
      })

      .state('brand.map', {
        url: '/brand/map',
        templateUrl: '/views/brand/brand-map.html',
        controller: 'brandmapcontroller',
        breadcrumb: 'Brand map controller',
        data: {
          pageTitle: 'BRAND_MAP'
        }
      })
      .state('brand.clientgroups', {
        url: '/brand/client-groups',
        templateUrl: '/views/brand/client-groups.html',
        controller: 'companyClientGroupsController',
        breadcrumb: 'Client Groups',
        data: {
          pageTitle: 'CLIENT_GROUPS'
        }
      })
      .state('brand.employees', {
        url: '/brand/employees',
        templateUrl: '/views/brand/employees.html',
        controller: 'employeesBrandController',
        breadcrumb: 'Employees',
        data: {
          pageTitle: 'EMPLOYEES'
        }
      })
      .state('brand.addemployee', {
        url: '/brand/addemployee',
        templateUrl: '/views/brand/add-employee.html',
        controller: 'addemployeeBrandController',
        breadcrumb: 'Add Employee',
        data: {
          pageTitle: 'EMPLOYEES'
        }
      })
      // .state('brand.employee', {
      //   url: '/brand/employee/:employeeId',
      //   templateUrl: '/views/brand/brand-employee.html',
      //   controller: 'employeeBrandController',
      //   breadcrumb: 'Add Employee',
      //   data: {
      //     pageTitle: 'EMPLOYEES'
      //   }
      // })
      .state('brand.myaccountpage', {
        url: '/brand/accountpage',
        templateUrl: '/views/brand/brand-my-account-page.html',
        controller: 'myAccountPageBrandController',
        breadcrumb: 'Account',
        data: {
          pageTitle: 'MY_ACCOUNT'
        }
      })
      .state('brand.purchase-orders', {
        url: '/brand/purchase-orders',
        templateUrl: '/views/brand/purchase-orders.html',
        controller: 'shopBrandPurchaseOrdersController',
        breadcrumb: 'Shops and Purchase-orders',
        data: {
          pageTitle: 'SELLING_ORDERS'
        }
      })
      .state('brand.purchase-order-statistics', {
        url: '/brand/purchase-order-statistics',
        templateUrl: '/views/brand/purchase-order-statistics.html',
        controller: 'shopBrandPurchaseOrderStatsticsController',
        breadcrumb: 'Shops and Purchase-orders',
        data: {
          pageTitle: 'SELLING_ORDER_STATISTICS'
        }
      })

      .state('brand.purchase-order', {
        url: '/brand/purchase-order/:orderNo',
        templateUrl: '/views/brand/purchase-order.html',
        controller: 'shopBrandPurchaseOrderController',
        breadcrumb: 'Shops and Purchase-order',
        data: {
          pageTitle: 'SELLING_ORDERS'
        }
      })

      // .state('brand.purchase-order', {
      //   url: '/brand/purchase-order/:orderNo',
      //   templateUrl: '/views/brand/purchase-order-new.html',
      //   controller: 'brandPurchaseOrderControllerNew',
      //   breadcrumb: 'Shops and Purchase-order',
      //   data: {
      //     pageTitle: 'SELLING_ORDERS'
      //   }
      // })

      // .state('brand.recommendation-order', {
      //   url: '/brand/recommendation-order/:orderNo?',
      //   templateUrl: '/views/brand/recommendation-order.html',
      //   controller: 'brandRecommendationOrderController',
      //   data: {
      //     pageTitle: 'SELLING_ORDERS'
      //   }
      // })

      .state('brand.recommendation-order', {
        url: '/brand/recommendation-order/:orderNo?',
        templateUrl: '/views/brand/recommendation-order-new.html',
        controller: 'brandRecommendationOrderControllerNEW',
        data: {
          pageTitle: 'SELLING_ORDERS'
        }
      })

      .state('brand.shop-detail', {
        url: '/brand/shop-detail/:nameSlug',
        templateUrl: '/views/brand/shop-detail.html',
        // templateUrl: '/views/brand/shop-detail-old.html',
        controller: 'shopDetailBrandController',
        breadcrumb: 'Shop Detail',
        data: {
          pageTitle: 'SHOP_DETAILS'
        }
      })
      .state('brand.edit-shop', {
        url: '/brand/edit-shop/:_id',
        templateUrl: '/views/brand/edit-shop.html',
        controller: 'shopEditBrandController',
        breadcrumb: 'Edit Shop',
        data: {
          pageTitle: 'EDIT_SHOP'
        }
      })
      .state('brand.social-portals', {
        url: '/brand/social-portals',
        templateUrl: '/views/brand/social-portals.html',
        breadcrumb: 'Shops and Contributions',
        data: {
          pageTitle: 'SOCIAL_PORTALS'
        }
      })
      .state('brand.add-product', {
        url: '/brand/assortment/add-product',
        templateUrl: 'views/company/product.html',
        controller: 'companyProductController',
        breadcrumb: 'Add product',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCTS'
        }
      })
      .state('brand.add-purchase-product', {
        url: '/brand/assortment/add-product/:number/:id',
        templateUrl: 'views/company/product.html',
        controller: 'companyProductController',
        breadcrumb: 'Add product',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCTS'
        }
      })

      .state('brand.edit-purchase-product', {
        url: '/brand/assortment/edit-product/:_id/:number/:id',
        templateUrl: 'views/company/product.html',
        controller: 'companyProductController',
        breadcrumb: 'Edit product',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCTS'
        }
      })
      .state('brand.edit-product', {
        url: '/brand/assortment/edit-product/:_id',
        templateUrl: 'views/company/product.html',
        controller: 'companyProductController',
        breadcrumb: 'Edit product',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: '',
          pageTitle: 'PRODUCTS'
        }
      })
      .state('brand.product-import', {
        url: '/brand/assortment/import',
        templateUrl: '/views/company/product-import.html',
        controller: 'companyProductImportController',
        data: {
          rights: 'MANAGE_PRODUCTS',
          module: 'PACKAGE_LINK_WITH_PRODUCT_DATABASE',
          pageTitle: 'PRODUCT_IMPORT'
        }
      })
      .state('brand.product-update-import', {
        url: '/brand/assortment/update-import',
        templateUrl: '/views/company/product-update-import.html',
        controller: 'companyProductUpdateImportController'
      })
      .state('brand.campaign', {
        url: '/brand/campaign',
        templateUrl: '/views/campaign/campaign.html',
        controller: 'brandCampaignController',
        breadcrumb: 'Social Media',
        data: {
          pageTitle: 'CAMPAIGNS'
        }
      })
      .state('brand.updatecampaign', {
        url: '/brand/updatecampaign/:campaignId',
        templateUrl: '/views/campaign/update-campaign.html',
        controller: 'updateBrandCampaignController',
        breadcrumb: 'Social Media',
        data: {
          pageTitle: 'CAMPAIGNS'
        }
      })
      .state('brand.campaigns', {
        url: '/brand/campaigns',
        templateUrl: '/views/campaign/campaigns.html',
        controller: 'brandCampaignsController',
        breadcrumb: 'Social Media',
        data: {
          pageTitle: 'CAMPAIGNS'
        }
      })
      .state('brand.campaigndetail', {
        url: '/brand/campaign/:campaignId',
        templateUrl: '/views/campaign/campaign-detail.html',
        controller: 'retailerBrandCampaignDetailController',
        data: {
          rights: 'MANAGE_SOCIALMEDIA_ACTIONS',
          pageTitle: 'CAMPAIGNS'
        }
      })
      .state('brand.retailerjoininglist', {
        url: '/campaign/retailerjoininglist/:campaignId',
        templateUrl: '/views/campaign/campaign-retailer-joining-list.html',
        controller: 'campaignRetailerJoiningList',
        data: {
          rights: 'MANAGE_SOCIALMEDIA_ACTIONS',
          pageTitle: 'CAMPAIGNS'
        }
      })
      .state('brand.services', {
        url: '/brand/service',
        templateUrl: '/views/brand/services.html',
        controller: 'brandServicesController',
        data: {
          pageTitle: 'TRANSACTIONS'
        }
      })
      .state('brand.service-item', {
        url: '/brand/service-item/:id',
        templateUrl: '/views/brand/service-item.html',
        controller: 'brandServiceItemController',
        data: {
          pageTitle: 'TRANSACTIONS'
        }
      })
      .state('brand.new-service', {
        url: '/brand/new-service',
        templateUrl: '/views/brand/new-service-item.html',
        controller: 'brandNewServiceItemController',
        data: {
          pageTitle: 'TRANSACTIONS'
        }
      })
      .state('brand.activity', {
        url: '/brand/activity',
        templateUrl: '/views/brand/activity.html',
        controller: 'brandActivityController',
        data: {
          pageTitle: 'ACTIVITY'
        }
      })
      .state('brand.activity-item', {
        url: '/brand/activity-item/:id',
        templateUrl: '/views/brand/activity-item.html',
        controller: 'brandActivityItemController',
        data: {
          pageTitle: 'TRANSACTIONS'
        }
      })
      .state('brand.new-activity', {
        url: '/brand/new-activity',
        templateUrl: '/views/brand/new-activity.html',
        controller: 'activityNewServiceItemController',
        params: {
          name: null
        },
        data: {
          pageTitle: 'ACTIVITY'
        }
      })
      .state('brand.orders', {
        url: '/brand/weborders',
        templateUrl: '/views/brand/weborders.html',
        controller: 'brandOrdersController',
        data: {
          rights: 'MANAGE_WEBSHOP_ORDER',
          module: 'PACKAGE_SELL_DIRECTLY_ON_YOUR_WEBSHOP',
          pageTitle: 'WEBORDERS'
        }
      })
      .state('brand.orderDetails', {
        url: '/brand/webshop/:transactionId',
        templateUrl: '/views/brand/weborder.html',
        controller: 'brandWebshopOrderDetailsController',
        data: {
          rights: 'MANAGE_WEBSHOP_ORDER',
          module: 'PACKAGE_SELL_DIRECTLY_ON_YOUR_WEBSHOP',
          pageTitle: 'WEBORDERS'
        }
      })
      .state('brand.add-shop', {
        url: '/brand/add-shop',
        templateUrl: '/views/brand/add-shop.html',
        controller: 'brandAddShopController',
        data: {
          pageTitle: 'SHOPS'
        }
      })

      // #endregion

      // #region General/Layout routes here
      .state('layout', {
        abstract: true,
        templateUrl: '/views/layout.html'
      })
      .state('layout.welcome-retailer', {
        url: '/welcome-retailer/:hashedData',
        templateUrl: '/views/welcome-retailer.html',
        controller: 'welcomeRetailerController'

      })

      .state('layout.welcome-from-invitation', {
        url: '/welcome-invitation-link?searchlink',
        templateUrl: '/views/welcome-from-invitation-link.html',
        controller: 'welcomeFromInvitationLinkController'
      })

      .state('layout.company-invitation', {
        url: '/company-invitation?searchlink',
        templateUrl: '/views/company-invitation.html',
        controller: 'companyInvitationController'
      })

      .state('layout.welcome-campaigns', {
        url: '/retailer/welcome-campaigns/:companyName/:hashedData/:brandId',
        templateUrl: '/views/welcome-campaigns.html',
        controller: 'welcomeCampaignController'
      })
      .state('layout.home', {
        url: '/home',
        templateUrl: '/views/home.html',
        controller: 'homeController'
      })
      .state('layout.login', {
        url: '/login?searchlink/:to?',
        templateUrl: 'views/login.html',
        controller: 'homeLoginController',
        params: {
          to: null
        }
      })
      .state('layout.register', {
        url: '/register',
        templateUrl: 'views/register.html',
        controller: 'homeRegisterController',
        params: {
          email: null
        }
      })
      .state('layout.company-register', {
        url: '/companyregister',
        templateUrl: 'views/company-register.html',
        controller: 'companyRegisterController',
        params: {
          email: null
        }
      })
      .state('layout.request-password', {
        url: '/request-password',
        templateUrl: 'views/request-password.html',
        controller: 'homeResetPasswordController'
      })
      .state('layout.resetpassword', {
        url: '/reset-password/:code',
        templateUrl: 'views/reset-password.html',
        controller: 'homeResetPasswordController',
        params: {
          force: false
        }
      })
      .state('layout.terms-of-service-retailers', {
        url: '/terms-of-service-retailers',
        templateUrl: '/views/terms-of-service-retailers.html'
      })
      .state('layout.terms-of-service-brands', {
        url: '/terms-of-service-brands',
        templateUrl: '/views/terms-of-service-brands.html'
      })
      .state('layout.privacy-policy', {
        url: '/privacy-policy',
        templateUrl: '/views/privacy-policy.html'
      })
      .state('layout.activate-user', {
        url: '/activate-user/:code?shopname&searchlink',
        templateUrl: 'views/activate-user.html',
        controller: 'homeRegisterController'
      })
      .state('layout.company-activate-user', {
        url: '/company-activate-user/:hashedparam',
        templateUrl: 'views/activate-user.html',
        controller: 'companyActivateUserController'
      })
      .state('layout.access-denied', {
        url: '/access-denied/:to',
        templateUrl: 'views/acces-denied.html',
        controller: 'authController'
      })
      .state('layout.verify-success', {
        url: '/email-verify-success',
        templateUrl: 'views/verify-success.html'
      })
      .state('layout.verify-failure', {
        url: '/email-verify-failure',
        templateUrl: 'views/verify-failure.html'
      })
      .state('layout.my-account', {
        url: '/my-account',
        templateUrl: 'views/account/index.html',
        controller: 'accountController',
        data: {
          restricted: true
        }
      })
      .state('layout.my-account.home', {
        url: '/home',
        templateUrl: 'views/account/home.html'
      })
      .state('layout.my-account.my-details', {
        url: '/my-details',
        templateUrl: 'views/account/my-details.html'
      })
      .state('layout.my-account.orders', {
        url: '/orders',
        templateUrl: 'views/account/orders.html'
      })
      .state('layout.my-account.notes', {
        url: '/notes',
        templateUrl: 'views/account/notes.html'
      })
      .state('layout.my-account.password', {
        url: '/password',
        templateUrl: 'views/account/password.html'
      })
      .state('layout.logout', {
        url: '/logout',
        templateUrl: 'views/logout.html'
      })
      .state('layout.setpassword', {
        url: '/set-password/:id/:code',
        templateUrl: 'views/set-password.html',
        controller: 'authController'
      })
      .state('layout.brandblacklist', {
        url: '/unsubscribe-brand/:code',
        templateUrl: 'views/unsubscribe-brand.html',
        controller: 'unsubscribeBrandController'
      })
    // #endregion
    $locationProvider.html5Mode({ enabled: true, requireBase: false })
    $sceProvider.enabled(false)
  }
])
